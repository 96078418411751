.Watermark {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 20px;
    bottom: 20px;
    background: rgba(255,255,255,1);
    opacity: 0.35;

    .imagination-logo, .meetvr-logo {
        height:2em;
    }
}