.VR {

  width: 100%;
  height: 100%;
  
  &.is-presenter {
    width: 960px;
    height: 480px;
    margin: 0 1em;
  }


  opacity: 1;
  transition: opacity 0.5s;

  &.fadeout {
    opacity: 0;
  }
  
}


.vr-container {
  width: 960px;
  height: 480px;
  margin: 0 1em;
}

.vr-outer {
  background-color: black;
  width: 100%;
  height: 100%;
}

#customARButton {
  display: none;
}