.Scene {
  padding: 0.25em;
  
  h1 {
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: solid 2px;
  }

  .activate {
    
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.25em;
    padding: 0.2em 1em;
    border: solid 1px;

    .icon {
      margin-left: 0.5em
    }

  }

}