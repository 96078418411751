.Viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; 
}

.viewer-join-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .message {
        margin: 1.5em 0 1.75em 0;
        font-size: 1.5em;
        .title {
            font-weight: bold;
        }
    }

    button {
        font-size: 1.25em;
        padding: 0.25em 2em;
    }
}