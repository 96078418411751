.Header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0.5em;
 

  .imagination {
    height: 3em;
  }

  .meetVR {
    height: 3em;
  }

  .Header__presentation {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    label {
      font-size: 1.25em;
      margin-bottom: 0.25em;

      .name {
        font-weight: bold;
      }

    }
  }
}