.Scenes {

  display: flex;
  flex-direction: column;
  padding: 0.25em;

  h1 {
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: solid 2px;
  }

  .list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 0.25em;
  }

  .item {
    display: flex;
    flex-direction: row;
    padding: 0.2em;
    align-items: center;
    border: solid 1px transparent;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    .title {
      flex:1;
    }

    .icon-container {
      width: 4em;
      flex:0;
    }

    .icon {
      visibility: hidden;
      padding: 0 0.1em;
      margin-left: 0.5em;
      width: 2.5em;
    }

    &.active {
      .icon {
        visibility: visible;
      }
    }

    &.selected {
      background-color: black;
      color: white;
      border: dotted 1px transparent;
    }

  }

}