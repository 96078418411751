* {
  padding: 0;
  margin: 0;
  user-select: none;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: white;
}

#root {
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: 1.5rem;
}


.a-modal {
  display: none!important;
}

.fullscreen {
  position: fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}