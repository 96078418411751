.Presenter {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 0.5em;
  margin: 0 auto;

  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: black;
    padding: 1em 0;
  }

  .scene-controls {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    padding: 0.5em;

    .Scene {
      flex: 1;
      margin-left: 1em;
    }
  }

}